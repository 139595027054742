import KoobModal from '../../../components/Koob/KoobModal';
import KoobButton from '../../../components/Koob/KoobButton';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ExperienceExtrasModalHeader from './ExperienceExtrasModalHeader';
import ExperienceExtrasModalListItem from './ExperienceExtrasModalListItem';
import ExperienceExtrasModalSkipTransitionExtra from './ExperienceExtrasModalSkipTransitionExtra';
import ExperienceExtrasModalSkipChildrenExtra from './ExperienceExtrasModalSkipChildrenExtra';
import ExperienceExtrasModalRecommendationCancelButton from './ExperienceExtrasModalRecommendationCancelButton';
import { ExtraRequirementEnum } from '@koob/enums';

export default function ExperienceExtrasModal({
  open,
  setOpen,
  experience,
  experienceComposition,
  parentExtra,
  extrasScope,
  extras,
  selectedExtras,
  setSelectedExtras,
  prices,
  currency,
  onContinue,
  skipChildrenExtra,
  setSkipChildrenExtra,
  recommendationMode = false,
  readOnly = false,
  source,
  dayIndex,
  nextExperience,
  onCancelAdd
}) {
  const { t} = useTranslation('experiences');

  const appliedParentExperience = nextExperience ? [nextExperience] : [parentExtra];
  const appliedExperienceCompatibilies = Boolean(source) ? appliedParentExperience : [experience];

  const bestMatchIds = appliedExperienceCompatibilies.flatMap(item => {
    if (item?.bestMatches) {
      return item?.bestMatches.map(bestMatches => bestMatches?.id);
    } else {
      return [];
    }
  });

  const incompatibleIds = appliedExperienceCompatibilies.flatMap(item => {
    if (item?.incompatibilities) {
      return item.incompatibilities.map(
        incompatibilities => incompatibilities.id,
      );
    } else {
      return [];
    }
  });

  const formattedExtras = extras.map(extra => {
    const isBestMatch = bestMatchIds.includes(extra.id);
    const isIncompatible = incompatibleIds.includes(extra.id);

    return {
      ...extra,
      compatibility: isBestMatch
        ? 'bestMatch'
        : isIncompatible
        ? 'incompatible'
        : 'neutral',
    };
  });

  formattedExtras?.sort((a, b) => {
    if (a.compatibility === 'bestMatch' && b.compatibility !== 'bestMatch') {
      return -1;
    }
    if (a.compatibility !== 'bestMatch' && b.compatibility === 'bestMatch') {
      return 1;
    }
    if (a.compatibility === 'neutral' && b.compatibility !== 'neutral') {
      return -1;
    }
    if (a.compatibility !== 'neutral' && b.compatibility === 'neutral') {
      return 1;
    }
    return 0;
  });

  const setOpenWithRecommendation = (value) => {
    if (!recommendationMode) {
      setOpen(value);
    }
  };

  const allRequiredChildrenExtras = extras.filter(e => e.type === ExtraRequirementEnum.REQUIRED);

  const selectedRequiredChildrenExtras = selectedExtras.filter(se => {
    return (
      se.parentExtraId === parentExtra?.id &&
      se.dayIndex === dayIndex &&
      se.experienceId === experience?.id &&
      se.type === ExtraRequirementEnum.REQUIRED &&
      se.scope === extrasScope
    );
  });

  const hasCompletedSelection =
    allRequiredChildrenExtras?.length === 0 ||
    selectedRequiredChildrenExtras?.length === 1;

  const confirmChoice = () => {
    onContinue();
    setOpen(false);
  };

  return (
    <>
      <KoobModal
        open={open}
        setOpen={(value) => {
          setOpenWithRecommendation(value);
          if (!parentExtra) {
            onContinue();
          }
        }}
        allowClose={!parentExtra}
        widthClass="sm:max-w-7xl"
      >
        <div className="px-3 max-h-[600px] overflow-y-auto">
          <ExperienceExtrasModalHeader
            recommendationMode={recommendationMode}
            childrenExtraMode={!!parentExtra}
            currentExperienceName={experience?.name}
            nextExperienceName={nextExperience?.name}
          />

          {formattedExtras?.length > 0 && (
            <div className="my-8 grid sm:grid-cols-3 gap-5">
              {formattedExtras?.map(({ id, type, compatibility }) => (
                <ExperienceExtrasModalListItem
                  key={id}
                  extraId={id}
                  extraType={type}
                  extraScope={extrasScope}
                  compatibility={compatibility}
                  selectedExtras={selectedExtras}
                  setSelectedExtras={setSelectedExtras}
                  parentExtra={parentExtra}
                  dayIndex={dayIndex}
                  prices={prices}
                  currency={currency}
                  experience={experience}
                  experienceComposition={experienceComposition}
                  source={source}
                  disabled={(
                    selectedRequiredChildrenExtras?.length && type === 'required'
                  ) || skipChildrenExtra}
                  readOnly={readOnly}
                />
              ))}
            </div>
          )}
        </div>

        {recommendationMode && (
          <ExperienceExtrasModalSkipTransitionExtra
            dayIndex={dayIndex}
            onSkip={() => setOpen(false)}
          />
        )}

        {parentExtra && !readOnly && (
          <ExperienceExtrasModalSkipChildrenExtra
            skipChildrenExtra={skipChildrenExtra}
            setSkipChildrenExtra={setSkipChildrenExtra}
            onContinue={() => confirmChoice()}
            disabled={selectedRequiredChildrenExtras?.length}
          />
        )}

        <div className="mt-5 flex space-x-3 justify-end">
          {readOnly && (
            <KoobButton color="secondary" onClick={() => setOpen(false)}>
              {t('misc:close')}
            </KoobButton>
          )}

          {!readOnly && (
            <>
              {recommendationMode && (
                <ExperienceExtrasModalRecommendationCancelButton
                  onCancelAdd={onCancelAdd}
                />
              )}

              {!parentExtra ? (
                <KoobButton
                  onClick={() => confirmChoice()}
                  disabled={
                    recommendationMode &&
                    !selectedExtras.some((se) =>
                      extras.some((ei) => ei.id === se.id),
                    )
                  }
                  cypressId={'save-primary-options'}
                >
                  {t('misc:save')}
                </KoobButton>
              ) : (
                <>
                  <KoobButton
                    color="secondary"
                    onClick={() => setOpen(false)}
                    disabled={hasCompletedSelection}
                  >
                    {t('misc:cancel')}
                  </KoobButton>

                  <KoobButton
                    onClick={() => confirmChoice()}
                    disabled={!hasCompletedSelection}
                    cypressId={'save-secondary-options'}
                  >
                    {t('misc:save')}
                  </KoobButton>
                </>
              )}
            </>
          )}
        </div>
      </KoobModal>
    </>
  )
}
