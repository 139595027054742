import React, { useState } from 'react';
import { useAsync } from 'react-async';
import { getExperience } from '../../api/experiences';
import KoobPlaceholder from '../Koob/KoobPlaceholder';
import '../../styles/react-calendar.css';
import { useTranslation } from 'react-i18next';
import KoobButton from '../Koob/KoobButton';
import moment from "moment";
import { ExperiencePeriodStateEnum, ExperiencePricingTypeEnum } from "@koob/enums";
import { useExperienceBooking } from "../../hooks";
import ExperienceAvailabilityLegend from "./ExperienceAvailabilityLegend";
import ExperienceAvailabilityCalendar from "./ExperienceAvailabilityCalendar";
import { KoobParagraph } from "@koob/ui";

export default function ExperienceAvailability({ experienceId, onSelect, minDate, maxDate }) {
  const { t } = useTranslation('experiences');
  const { experienceFilters: filters } = useExperienceBooking();
  const { data, isLoading } = useAsync({
    promiseFn: getExperience,
    experienceId,
  });

  const [selectedDates, setSelectedDates] = useState(null);

  const experience = data?.data ?? {};
  const periods = experience?.periods ?? [];

  const totalPax = filters?.experienceComposition?.reduce((acc, composition) => {
    return acc + Number(composition.adults) + Number(composition.children);
  }, 0) ?? 0;

  const isGuaranteedDeparture = experience?.pricingType !== ExperiencePricingTypeEnum.FIT;

  const findPeriods = (date) => {
    return periods.filter((period) => {
      const startDate = moment(period.startAt).startOf('day');
      const endDate = moment(period.endAt).endOf('day');

      if (moment(date).isBetween(startDate, endDate, 'days', '[]')) {
        const dayOfWeek = date.getDay();
        if (period?.availableDays[(dayOfWeek+6)%7] && totalPax >= period.minPax && totalPax <= period.maxPax) {
          return true;
        }
      }
      return false;
    });
  };

  // isCheckingRange is used when checking the availability of a range of dates (hovering)
  // as opposed to checking availability for a single date (initial display)
  const getDayAvailability = (date, isCheckingRange = false) => {
    const availablePeriods = findPeriods(date);
    if (!availablePeriods.length) return ExperiencePeriodStateEnum.CLOSED;

    if (!isGuaranteedDeparture) {
      return availablePeriods[0].state ?? ExperiencePeriodStateEnum.CLOSED;
    }

    // For initial display and selection, only starting dates
    const startingPeriod = availablePeriods.find(period =>
      moment(date).isSame(moment(period.startAt), 'day')
    );

    if (!isCheckingRange) {
      if (!startingPeriod) return ExperiencePeriodStateEnum.CLOSED;
      if (startingPeriod.allotments < startingPeriod.allotmentsUsed + totalPax) {
        return ExperiencePeriodStateEnum.ON_REQUEST;
      }
      return startingPeriod.currentPaxForDeparture + totalPax >= startingPeriod.minPaxForDeparture
        ? ExperiencePeriodStateEnum.FREE_SALE
        : ExperiencePeriodStateEnum.PRE_CONFIRMED;
    }

    // When checking range, use the period that started most recently
    // This is to avoid showing the most restrictive availability when multiple periods overlap
    const relevantPeriod = availablePeriods.find(period =>
      moment(date).isSameOrAfter(moment(period.startAt), 'day')
    );

    if (!relevantPeriod) return ExperiencePeriodStateEnum.CLOSED;

    if (relevantPeriod.allotments < relevantPeriod.allotmentsUsed + totalPax) {
      return ExperiencePeriodStateEnum.ON_REQUEST;
    }
    return relevantPeriod.currentPaxForDeparture + totalPax >= relevantPeriod.minPaxForDeparture
      ? ExperiencePeriodStateEnum.FREE_SALE
      : ExperiencePeriodStateEnum.PRE_CONFIRMED;
  };

  return !isLoading ? (
    <div>
      <div className="mb-3">
        <KoobParagraph childClass="text-center">
          {t("availability.durationdays", { count: experience?.durationDays })}
        </KoobParagraph>
      </div>

      <div className="flex justify-center">
        <ExperienceAvailabilityCalendar
          selectedDates={selectedDates}
          setSelectedDates={setSelectedDates}
          defaultDate={filters?.startAt ? moment(filters.startAt).startOf('month').toDate() : null}
          minDate={minDate ?? new Date()}
          maxDate={maxDate}
          duration={Math.max(1, experience?.durationDays)}
          getDayAvailability={getDayAvailability}
        />
      </div>

      <div className="mt-5 flex justify-between items-center">
        <ExperienceAvailabilityLegend />

        <div className="shrink-0">
          <KoobButton
            onClick={() => onSelect(selectedDates)}
            disabled={!selectedDates}
            cypressId="select-period"
          >
            {t("misc:continue")}
          </KoobButton>
        </div>
      </div>
    </div>
  ) : (
    <div>
      <div className="mb-5 max-w-sm mx-auto">
        <KoobPlaceholder className="h-7 w-full rounded" />

        <div className="mt-5">
          <KoobPlaceholder className="h-12 w-full rounded" />
          <KoobPlaceholder className="mt-3 h-[330px] w-full rounded" />
        </div>
      </div>

      <KoobPlaceholder className="h-12 w-full rounded" />
    </div>
  );
}
