import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { ExperienceMarker } from '@koob/experiences';
import { GOOGLE_MAPS_API_KEY } from '@koob/to/src/constants';
import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { KoobPlaceholder } from '@koob/ui';

function ExportItinerarySummaryDay({ index, day, experience }) {
  const program = experience?.programs[day.experienceDayIndex];

  return (
    <div>
      <div className="font-medium text-gray-800">
        Day {index + 1} — {program?.title ?? experience?.name}
      </div>
      <div className="text-gray-800">
        {experience?.inCity?.title}, {experience?.inCity?.country?.title}
      </div>
    </div>
  );
}

export default function ExportItinerarySummary({ trip, experiences, mapHeight = '700px', page, perPage }) {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY
  });
  const [map, setMap] = useState();

  const onLoad = mapInstance => {
    if (!Boolean(map)) {
      setMap(mapInstance);
    }
  };

  useDeepCompareEffect(() => {
    if (!Boolean(map)) {
      return;
    }

    const bounds = new window.google.maps.LatLngBounds();
    experiences
      .filter((experience) => Boolean(experience?.inCity?.lat) || Boolean(experience?.inCity?.lon))
      .forEach((experience) =>
        bounds.extend(new window.google.maps.LatLng(parseFloat('' + experience?.inCity?.lat), parseFloat('' + experience?.inCity?.lon))),
      );
    if (experiences.length === 0) { // default bounds if no experiences
      bounds.extend(new window.google.maps.LatLng(50.633, 3.066));
    }
    map.fitBounds(bounds);
  }, [ experiences, map ]);

  const onUnmount = () => {
    setMap(null);
  };

  return (
    <div className="grid grid-cols-2 gap-16">
      <div className={`h-[${mapHeight}] w-[600px] rounded-lg overflow-hidden mx-auto`}>
        {isLoaded ? (
          <GoogleMap
            mapContainerStyle={{ height: '100%', width: '100%', borderRadius: '1.5rem', overflow: 'hidden' }}
            options={{ maxZoom: 16, mapTypeControl: false, streetViewControl: false, fullscreenControl: false }}
            zoom={12}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            {experiences?.map((experience) => {
              experience.experienceType = 'Program'

              return (
                <ExperienceMarker
                  key={'map-e-' + experience?.id}
                  experience={experience}
                  mode="trip-export"
                />
              );
            })}
          </GoogleMap>
        ) : (
          <KoobPlaceholder className={`h-[${mapHeight}] w-full rounded-lg`} />
        )}
      </div>

      <div className="flex-col space-y-5">
        {trip?.tripDays?.slice((page - 1) * perPage, page * perPage)?.map((day, index) => (
          <ExportItinerarySummaryDay
            key={index}
            index={index + (page - 1) * perPage}
            day={{...day, experienceDayIndex: index}}
            experience={experiences?.find(e => e.id === day?.experience?.id)}
          />
        ))}
      </div>
    </div>
  );
}
