import { createFolder, getUniqueIcr } from '@koob/dmc/src/api/node/folder';

export const createDefaultCostumerFile = async (organizationId, user) => {
    if (!organizationId || !user) {
      return Promise.resolve(null);
    }
  
    const icr = await getUniqueIcr(organizationId, {
      firstName: user.firstName,
      lastName: user.lastName,
    });
  
    const newFolderData = await createFolder({
      icr: icr?.data,
      name: user.lastName + ' ' + icr?.data,
      travelers: [],
    });
    const newFolder = newFolderData.data;
  
    return newFolder;
  };