import SearchTypeIcon from "./SearchTypeIcon";
import SearchResultTitle from "./SearchResultTitle";
import * as sanitizeHtml from 'sanitize-html';

export default function SearchResult({ result, query, onClick }) {
  const sanitizedDescription = sanitizeHtml(result.description, {
    allowedTags: [],
    allowedAttributes: {}
  })?.replace(/&amp;/g, '&');

  return (
    <div
      onClick={() => onClick()}
      className="group flex cursor-default select-none items-center rounded-md px-4 py-2 hover:bg-gray-900/5"
      data-cy=" search-result"
    >
      <SearchTypeIcon
        type={result.type}
        className="flex-none text-gray-900/40 group-hover:text-gray-900"
      />
      <div className="ml-3 flex-auto truncate">
        <div className="text-gray-900">
          <SearchResultTitle
            title={result.title}
            query={query}
          />
        </div>
        <div className="text-xs italic text-gray-500">
          {sanitizedDescription}
        </div>
      </div>
      <span className="hidden flex-none text-sm text-gray-500 group-hover:inline">
        <i className="fal fa-chevron-right" />
      </span>
    </div>
  )
}
