import { IcPencil } from 'components/icons';
import { ActionLink } from 'ui';

const FoldersBookingEditButton = ({ bookingId, cypressId }) => {
  return (
    <ActionLink to={`/bookings/${bookingId}`} data-cy={cypressId}>
      <IcPencil />
    </ActionLink>
  );
};

export default FoldersBookingEditButton;
