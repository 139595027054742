import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-async';
import { getHotel } from '@koob/to/src/api/hotels';
import { getBooking } from '@koob/to/src/api/bookings';
import { isEqual } from 'lodash';
import moment from 'moment/moment';
import sanitizeHtml from 'sanitize-html';
import React from 'react';
import ExportDaySectionTitle from './ExportDaySectionTitle';
import ExportDaySectionContent from './ExportDaySectionContent';

const sustainableLevels = {
  zero: 1,
  low: 2,
  medium: 3,
  strong: 4,
};

export default function ExportDayHotel({ hotelId, bookingId, dayData,isIncluded = false }) {
  const { t } = useTranslation('tripDesigner');

  const hotelData = useAsync({
    promiseFn: getHotel,
    hotelId,
  });
  const hotel = hotelData?.data?.data;
  const hotelDescription =  hotel?.hotelDescriptions?.find(
    (elem) => elem.content && elem.description.isMainDescription
  );

  const { data: hotelBookingData } = useAsync({
    promiseFn: getBooking,
    bookingId,
    skip: !bookingId,
    watchFn: (props, prevProps)=>{
      return !isEqual(props.bookingId, prevProps.bookingId)
    }
  });
  const booking = hotelBookingData?.data;
  const roomBooked = booking?.roomsBooked[0];
  let checkIn, checkOut;
  if (!bookingId) {
    const { trip, index } = dayData;
    checkIn = moment(trip?.startDate).add(index, 'days').format('DD/MM/YYYY');
    checkOut = moment(trip?.startDate).add(index + 1, 'days').format('DD/MM/YYYY');
  } else {
    checkIn = moment(booking?.startAt).format('DD/MM/YYYY');
    checkOut = moment(booking?.endAt).format('DD/MM/YYYY');
  }

  const images = [
    hotel?.primaryAttachment?.imageUrl,
    ...hotel?.secondaryAttachments?.map(attachment => attachment?.imageUrl).filter(Boolean) ?? [],
  ].filter(Boolean);

  return (
    <ExportDaySectionContent images={images}>
      <div className="-mt-6">
        <ExportDaySectionTitle>
          <i className="fa-regular fa-bed mr-2" />
          Accommodation
        </ExportDaySectionTitle>

        <div className="mt-3 bg-gray-100 p-8 rounded-lg flex-col space-y-3">
          <div>
            <div className="flex justify-between">
              <div>
                <div className="flex space-x-3 items-center">
                  <div className="font-medium text-2xl">
                    {hotel?.displayName}
                  </div>

                  {isIncluded && (
                    <div className="text-lg font-medium text-green-500">
                      {t('compose.priceIncluded')}
                    </div>
                  )}
                </div>

                <div className="text-gray-600 italic">
                  {hotel?.address}
                </div>
              </div>

              <div className="flex-col space-y-1.5">
                <div className="flex space-x-1.5 items-center">
                  {[...Array(5)].map((_, i) => (
                    <svg
                      key={i}
                      className={['h-5 w-5', i < hotel?.stars ? 'text-yellow-400' : 'text-gray-300'].join(' ')}
                      viewBox="0 0 576 512"
                    >
                      <path
                        d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                      />
                    </svg>
                  ))}
                </div>

                <div className="flex space-x-1.5 justify-end items-center">
                  <div className="flex items-center space-x-1">
                    {[...Array(sustainableLevels[hotel?.sustainable?.level])].map(
                      (_, i) => (
                        <i
                          key={i}
                          className="fa fa-regular fa-leaf text-green-600 text-xl"
                        />
                      ),
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex-col">
            <div className="font-medium text-xl">
              Rooms
            </div>
            <div className="text-gray-600">
              {roomBooked?.room?.name}
            </div>
            <div className="text-gray-600">
              {`Check in: ${checkIn} — Check out: ${checkOut}`}
            </div>
          </div>

          <div className="flex-col">
            <div className="font-medium text-xl">
              Description
            </div>
            <div dangerouslySetInnerHTML={{__html: sanitizeHtml(hotelDescription?.content) }}/>
          </div>
        </div>
      </div>
    </ExportDaySectionContent>
  );
}
